import Poster from "./Poster";
import leftPlant from "../../assets/left-plant.png";
import rightPlant from "../../assets/right-plant.png";
import CardFrame from "../../assets/card-frame.png";
import nft from "../../assets/nft.png";

export default function PosterConfigPage(){
    return(
        <Poster plant={plant} style={style}/>
    )
}

const plant = {left: leftPlant, right: rightPlant};

const style = {
    button: {
        buttonBackgroundColor: "#281EC8",
        buttonContentColor: "#ffffff",
    },
    titleStyle: {
        color: "#ffffff",
        fontSize: "15px",
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        marginTop: '35px'
    },
    background:{
      marginTop: '40px'
    },
    frame:{
        height: '356px',
        width: '293px',
        backgroundImage: CardFrame,
        contentImage: nft,
        content:{
            height: '266px',
            width: '266px'
        }
    },
    detailFrame: {
        marginTop: '20px'
    }
};

