import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "./autoswiper.scss";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Autoplay } from "swiper";
import {useState} from "react";
import Desc from './components/desc';
import cool from "../../assets/cool.png"
import Header from './components/header';
import Frame from "../frame/frame";

SwiperCore.use([FreeMode, Autoplay]);

export default function AutoSwiper(props){
    const {style, nftInfo, swiperContainer, spaceBetween, handleSlideChange, currentIndex} = props;

    return(
        <div style={{ height: style.swiper.height, width: style.swiper.width, position: 'absolute', marginTop: '120px'}}>
            {/* <div className="nft-id">*/}
            {/*  { nftInfo[currentIndex].header? <Header header={nftInfo[currentIndex].header} /> : null}*/}
            {/*</div>*/}
            <Swiper slidesPerView={'auto'}
                    spaceBetween={spaceBetween} centeredSlides={true} autoplay={false} loop={false}
                    className="home-swiper"
                    onSlideChange={handleSlideChange}>
                {
                    nftInfo.map((nft,index)=>{
                        return(
                            <SwiperSlide style={{height: style.swiper.container.height, width: style.swiper.container.width}} key={index}>

                                <div>
                                    <img src={nft.image} alt='' style={{height: '295px', width: '295px', borderRadius:'16px 16px 0 0'}}/>
                                    <div style={{height:'50px',width:'295px', borderRadius:'0 0 16px 16px',background:'white',display:'flex',alignItems:'center'}}>
                                        <img src={cool} style={{width:'32px', height: '32px',marginRight:'10px'}}/>
                                        <div style={{color:'black',fontSize:'14px',fontWeight:'500'}}>美的空调</div>
                                    </div>
                                </div>
                                {/*<Frame backgroundImage={swiperContainer} contentImage={nft.image}*/}
                                {/*height={style.swiper.container.height} width={style.swiper.container.width}*/}
                                {/*contentHeight={style.swiper.content.height} contentWidth={style.swiper.content.height}*/}
                                {/*desc={nftInfo[currentIndex].desc? <Desc desc={nftInfo[currentIndex].desc}/>: null}*/}
                                {/*/>*/}
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
          { nftInfo[currentIndex].desc? <Desc desc={nftInfo[currentIndex].desc}/>: null}
        </div>
    )

}
