import { portalAddress } from "../App";
import {responseCode} from "../config";

export default function useSMS(phone) {

  const post = async () => {
    try {
      const response = await fetch(
        portalAddress + "/user/sendSms",
        {
          method: "POST",
          body: JSON.stringify({ phone: phone }),
        }
      );
      const json = await response.json();
      if (json.returnCode === responseCode.success) {
        return true;
      }
      return false;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  return post;
}
