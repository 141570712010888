import './App.scss';
import { Routes, Route, BrowserRouter} from "react-router-dom";
import React from "react";
import Poster from "./pages/posterPage/PosterConfigPage";
import Claim from "./pages/claimPage/ClaimConfigsPage";
import NftList from "./pages/nftListPage/NftListConfigPage";
import {createContext, useEffect, useReducer} from "react";
import {responseCode} from "./config";
import MenuBar from "./components/MenuBar";
import About from "./pages/aboutPage/AboutConfigPage";
import Congrats from "./pages/congratsPage/CongratsConfigsPage";
import Login from "./pages/loginPage/LoginConfigPage";
import Home from "./pages/homePage/HomePageConfig";
import Detail from './pages/detailPage/DetailConfigPage';

const initialState = {
    isLogin: null,
    userId: null,
    accessToken: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("userId", action.payload.userId);
            localStorage.setItem("accessToken", action.payload.accessToken);
            return {
                ...state,
                isLogin: true,
                userId: action.payload.userId,
                accessToken: action.payload.accessToken,
            };
        case "LOGOUT":
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            return {
                ...state,
                isLogin: false,
                userId: null,
                accessToken: null,
            };
    }
}

export const portalAddress = process.env.REACT_APP_PORTAL_ADDRESS;
export const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
export const appId = "wilding";
export const AuthContext = createContext();

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(async() => {
        let userId = localStorage.getItem("userId");
        let accessToken = localStorage.getItem("accessToken")
        if (userId && accessToken) {
            try {
                const response = await fetch( portalAddress + "/user/token", {
                    method: "GET",
                    headers: { accessToken },
                } );
                const json = await response.json();
                if (json.returnCode === responseCode.success) {
                    dispatch({
                        type: "LOGIN",
                        payload: {
                            isLogin: true,
                            userId,
                            accessToken
                        }
                    })
                }else{
                    dispatch({
                        type: "LOGOUT",
                    })
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            dispatch({
                type: "LOGOUT",
            })
        }
    },[])
  return (
    <div className="App" id='app'>
      <AuthContext.Provider value = {{state, dispatch}}>
          <BrowserRouter>
              <MenuBar />
              <Routes>
                      <Route path = "/" element = {<Home />}/>
                      <Route path = "/about" element = {<About />}/>
                      {/*<Route path = "/claim" element = {<Claim />} />*/}
                      <Route path = "/list" element = {<NftList />} />
                      <Route path = "/congrats" element = {<Congrats />} />
                      <Route path = "/detail/:id" element = {<Detail />} />
                      <Route path = "/poster/:id" element = {<Poster />} />
                      <Route path = "/login" element = {<Login />} />
              </Routes>
          </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
