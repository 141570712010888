import { useImperativeHandle, useState, forwardRef } from 'react';
import { Dialog } from '@mui/material';
import "./Agreement.scss";
import closeButton from "../../assets/close-button.svg";

function Agreement(props, ref) {

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openAgreement() {
      setOpen(true);
    }
  }), [])

  const handleClose = () => {
    setOpen(false);
  }

  const contentArray = content.split('。');

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <div className="agreement">
        <div className="about-title">
          <img width="12.83" height="12.72" style={{position: 'absolute', left: '16px'}} src={closeButton} onClick={handleClose} />
          《用户使用协议》
        </div>
        <div className="agreement-content">
          <p>活动名称：美的数字藏品领取活动</p>
          <p>数字藏品的领取/发行时间：2022年5月27日-2022年6月18日</p>
          {contentArray.map((content,index)=>{
            return (

                  <div key={index}>
                    {index !== contentArray.length -1 ?`${content}。` : null}
                  </div>
            )
          })}
        </div>
      </div>
    </Dialog>
  )
}

export default forwardRef(Agreement)

const content = `您（指拥有咖菲科技领取码的用户）使用领取码在数字藏品发行站点完成验证并获得对应的数字藏品。
每个领取码仅可获得一次数字藏品领取机会，领取后即失效。
您需要验证并授权绑定手机号领取以及提取您的数字藏品或使用本站点服务。
在您登陆本站点时，需要您同意我们获取您的手机号；如果您不授予前述同意，您将无法领取或提取数字藏品，并可能无法正常使用本站点的服务。
上海咖菲信息科技有限公司（以下简称“我们”）将严格遵守相关法律法规与监管要求，依法使用您的信息，并对您的信息保密。
您需要在咖菲科技站点进行验证并授权绑定手机号作为领取数字藏品的唯一凭证。
特别提示：您的手机号及领取码请用户务必自行妥善保管。
因个人原因，如泄露、遗失、转号等，而导致数字藏品遗失、或被盗、或冒领等后果及损失由您自行承担。
如发现用户恶意利用多个手机号或者其他不正当行为获取数字藏品，该用户的领取资格及数字藏品对应的相关权益将会被取消，所引发的一切法律责任和损失由该用户承担。
每一份数字藏品都是非卖品。
数字藏品仅可用于您的个人收藏，学习，研究，欣赏和展示之目的，不可转赠、不可售卖或以其它任何形式进行转让，亦不可用于任何商业行为，并且严格禁止炒作、场外交易、欺诈等任何非法用途，由此所产生违法及违约责任由用户自行承担。
因本次活动中的数字藏品都是非卖品，故不允许用户因个人原因申请退款。
咖菲科技将托管您领取的数字藏品。您可以在本站点领取数字藏品后，可通过http://midea.cocafe.co/查看所持有的数字藏品： 站点H5首页- 个人中心- 我的数字藏品，该查看路径的保留时间以另行通知为准；
你还可以通过咖菲科技的星际岛查看您领取的数字藏品。
您不得对所持有的数字藏品进行转赠、售卖或以其它任何形式进行转让。
您领取数字藏品后，利用该数字藏品实施的违约、违法等任何行为，由您个人自行承担一切责任。
您确认，一旦您以在线点击确认或其他方式签署本协议，即视为您已阅读并同意本协议的所有条款，且知晓并理解本协议所有条款的含义及相应的法律后果，本协议即视为已于您在线点击确认或以其他方式签署本协议之日起有效签署，对各方成立并生效。
本次活动数字藏品的领取/发行对象为具有完全民事行为能力的公民。
如您在本站点领取数字藏品，即表示您已经知晓并符合了该领取标准。
若您因未符合承担民事责任的能力而导致发生的任何违反法律法规的行为，我们不承担任何法律责任，同时本站点保留取消您领取资格的权利。
基于区块链技术的特性，数字藏品具有唯一、不可篡改的特点，数字藏品中的数字内容，铸造时间等信息无法篡改。
在您获得数字藏品后，您与数字藏品的所有权等权利关系将通过智能合约记录在区块数据中。
您理解并同意，您领取数字藏品后，只拥有该数字藏品本身的使用权，不拥有该数字藏品对应的原艺术品的所有权及包括著作权在内的各项知识产权，亦不拥有该数字藏品的知识产权。
您理解并同意，基于维护网络秩序和安全，如我们发现您存在违法违规或违反本协议约定情形的，我们有权采取同时采取一项或多项措施，包括但不限于： A) 要求您限期纠正违规或违约行为； B) 屏蔽或限制您使用数字藏品； C) 对违法违规信息进行删除或屏蔽，取消您的领取资格或数字藏品对应的相关权益 ；D) 停止或终止向您提供部分或全部服务； E) 其他合理、合法的措施。
我们因任何原因未采取上述任何措施，不代表我们放弃未来采取该等措施的权利。
如您的行为使我们遭受损失的，我们还有权要求您赔偿我们的全部损失，包括商誉损失、赔偿金、律师费、诉讼费等。
您理解并同意，在您获得数字藏品和使用本服务的过程中，可能会遇到不可抗力等风险因素。
如出现下列任一情况致使我们无法履行本协议约定的，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您或第三方造成的损失，我们不承担责任： A) 在与本服务相关的系统停机维护或升级期间； B) 因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和政府行为等不可抗力原因； C) 您操作不当或通过非我们授权或认可的方式使用本服务； D) 因病毒、木马、恶意程序攻击网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、电信设备故障、第三方服务瑕疵等原因。
您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。
我们可能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，但我们会尽快通知您前述情况，而您不会因此追究我们的责任。
除上文已经特别指出的以外，站点上的所有内容，包括但不限于文字、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计等，均由我们依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
非经我们书面同意，您不得擅自使用、修改、反向编译，复制，公开传播、改变、散布、发行或公开发表、转让、许可、商业利用本站点的相关软件、内容以及我们享有的知识产权。
否则我们有权立即终止本协议并要求您停止违约和/或侵权行为，您应对您的违约或侵权行为给我们或其他权利人造成的损失承担相应赔偿责任。
为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。
我们也会根据国家法律法规的变化要求，更新本协议的相关条款。
更新的内容将于协议更新公告公布的生效之日起生效。
若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您应立即停止使用相关服务。
若您继续使用本协议相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束。
在法律允许的范围内，以上说明的最终解释权归上海咖菲信息科技有限公司所有。
上海咖菲信息科技有限公司保留任何对针对用户使用本站点服务细则的修改，更新和迭代的权利。
具体请见本站点的公示。
`
