import React from 'react';
import NFTlist from "./NFTlist";
import Background from "../../components/background/background";
import CardFrameWeb from '../../assets/card-frame-web.png';
import AddFrame from '../nftListPage/assets/add.png';

export default function NftListConfigPage() {
    return(
     <div>
         {/*<Background ornamentConfig={[true,false,false, false]} textConfig={false} style={style}/>*/}
         <NFTlist style={style}  CardFrame={CardFrameWeb} AddFrame={AddFrame}/>
     </div>
    )
}

const style = {
    frame: {
        height: '149px',
        width: '149px',
        content:{
            height: '145px',
            width: '145px'
        }
    },
    addFrame: {
        content:{
            height: '40px',
            width: '40px'
        }
    },
    background:{
        marginTop: '61px'
    }
}