import {useNavigate} from "react-router";
import "./congrats.scss";
import Loading from "../../components/loading/Loading";
import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import BackgroundImage from '../../assets/background.png';

import DutyFree from "../../components/DutyFree";
import {AuthContext, serverAddress} from "../../App";
import cool from "../../assets/cool.png";
import light from "../../assets/back-light.png"
import Quotation1 from "../../components/autoSwiper/assets/quotation1.svg";
import Quotation2 from "../../components/autoSwiper/assets/quotation2.svg";
import background from "../../assets/background.png"

export default function Congrats(props){
    const { title, plant, style, buttonContent} =props;
    let navigate = useNavigate();
    let [params] = useSearchParams();
    const { state } = useContext(AuthContext);

    const confirm = () => {
        navigate('/list');
    }

    const url = params.get("url");
    const name = params.get("name");
    const desc = params.get("desc");
    const tokeId = params.get("tokenId")

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        },1000)
    },[])
    return(
        <div id="congrats-container">
            <img src={background} style={{width:'100vw',height:'100vh',zIndex:4, position:"absolute"}}/>
           <div style={{color:'black',marginTop:'40px',marginBottom:'-35px', fontSize:'22px', fontWeight:'500',zIndex:5}}>
               <div>{name}</div>
               <div style={{fontSize:'14px', fontWeight:'400',marginTop:'10px'}}># {tokeId}</div>
           </div>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',zIndex:5,marginTop:"110px"}}>
                {/*<img src={light} />*/}
                <img src={url} alt='' style={{height: '295px', width: '295px',borderRadius:'12px'}}/>
            </div>

            <div className="desc-container" style={{color: 'black',zIndex:5}}>
                <div>
                    <img src={Quotation1} alt='' style={{ paddingBottom: '20px', paddingRight: '10px' }}/>
                </div>
                <div className="desc-content">
                    {desc}
                </div>
                <img src={Quotation2} alt='' style={{ paddingTop: '20px',paddingLeft: '10px' }}/>
            </div>
            <div id='retrieve-button' onClick={confirm} style={{ backgroundColor: style.button.buttonBackgroundColor, color: style.button.buttonContentColor,zIndex:5}}>
                {buttonContent}
            </div>
            <div className='duty-container'>
                <DutyFree />
            </div>
            <Loading isShowing={!loaded} backgroundImage={BackgroundImage} text={'努力生成中...'}/>
        </div>
    )
}
