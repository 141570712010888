import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import BackButton from "../../components/backButton/BackButton";
import "./poster.scss";
import * as htmlToImage from "html-to-image";
import Loading from "../../components/loading/Loading";
import BackgroundImage from '../../assets/background.png';
import Background from "../../components/background/background";
import CommonTitle from "../../components/commonTitle/commonTitle";
import Frame from "../../components/frame/frame";
import QrCode from './assets/qr.png';
import Logo from '../../assets/slogan.png';
import cocafe from "../posterPage/assets/logo.png"
import Download from '../../assets/download.png';
import {bottomToast} from "../../utils/toast";


export default function Poster(props){
    const{ plant, style } =props;

    const { state } = useContext(AuthContext);
    const stageRef = useRef();

    const navigate = useNavigate();
    const [nft, setNft] = useState();
    const [loaded, setLoaded] = useState(false);
    const [nftLoaded, setNftLoaded] = useState(false);

    const [tokenId, setTokenId] = useState();
    const [owner, setOwner] = useState();
    const [name, setName] = useState();
    const [publisher, setPublisher] = useState();
    const [creator, setCreator] = useState();
    const [address, setAddress] = useState();
    const [total, setTotal] = useState();
    const params = useParams();

    // const [client, setOssClient] = useState(null);
    // const [frame, setFrame] = useState();
    // const [frameLoaded, setFrameLoaded] = useState(false);
    // const [titleLoaded, setTitleLoaded] = useState(false);
    // const output = useRef();
    // const ratio = window.devicePixelRatio;
    // const [templateLoaded, setTemplateLoaded] = useState(false);
    // const [title,setTitle] = useState();
    //
    // const [templateImg, setTemplate] = useState();
    // const imgWidth = 375;
    // const uiWidth = 375;
    // const uiHeight = 812;
    // const scale = deviceWidth / uiWidth;
    // const [deviceWidth] = useState(window.innerWidth);

    const displayPoster = (url) => {
        const parentDiv = document.getElementById('stageDiv');
        parentDiv.innerHTML ='';
        const result = document.createElement("img");
        // result.style.minHeight = '100vh';
        result.style.width = '100%';
        result.style.position = 'absolute';
        result.style.left = '0';
        result.style.top = '0';
        result.style.zIndex = '2';
        result.src = url;
        stageRef.current.appendChild(result);
        stageRef.current.scrollTo(0,0);
        result.onload = () => {
            setLoaded(true);
        };
    }
    const getSubStr = (str) => {
        let subStr1 = str.substr(0, 7);
        let subStr2 = str.substr(str.length - 19, 19);
        return subStr1 + "..." + subStr2;
    };


    const setInformation=(res)=>{
        setTokenId(res.data.tokenId);
        setOwner(res.data.ownedAt);
        setName(res.data.name);
        setPublisher(res.data.collectionInfo.publisher);
        setCreator(res.data.creator)
        setAddress(res.data.collectionInfo.contractAddress);
        setTotal(res.data.collectionInfo.amount)
    }

    const errorToast =() => {
        bottomToast('网络波动，请重试', 'center',()=>{
            navigate(`/detail/${params.id}`)
        })
    }



    useEffect(() => {
            if (state.isLogin) {
                fetch(serverAddress + "/nft/"+ params.id, { method: "GET" })
                    .then((res) => {
                        if (res.status === 200) {
                            res.json().then((res) => {
                                if (res.returnCode === "20000") {
                                    let url = res.data.thumbnailURL;
                                    let nftImg = document.createElement("img");
                                    nftImg.crossOrigin = "anonymous";
                                    nftImg.src = url;
                                    nftImg.onload = function(){
                                        setNftLoaded(true);
                                        setNft(nftImg.src);
                                    }
                                    setInformation(res)
                                }
                            }).catch(()=>errorToast());
                        }
                    });
            }
        }, [state.isLogin]);

    useEffect(() => {
        if (nftLoaded) {
            // setLoaded(true);
            setTimeout(() => {
                // const canvas = stageRef.current.toCanvas({pixelRatio: ratio});
                fetch(serverAddress + "/poster/isUploaded?nftId="+ params.id, {
                    method:"GET",
                    headers: {"accessToken": state.accessToken}}).then((res) => {
                    if(res.status === 200){
                        res.json().then( r => {
                            if(r.returnCode === "20000"){
                                // if(r.data.length === 0){
                                    htmlToImage.toBlob(stageRef.current).then(() => {
                                        htmlToImage.toBlob(stageRef.current).then(() => {
                                            htmlToImage.toBlob(stageRef.current).then((blob) => {
                                                let img = new File([blob],'poster.png');
                                                let formData = new FormData();
                                                formData.append('file', img);
                                                fetch(serverAddress + "/poster/uploadOss?nftId=" + params.id,{
                                                    method:"POST",
                                                    headers: {"accessToken": state.accessToken},
                                                    body: formData
                                                }).then((res) => {
                                                    if(res.status === 200){
                                                        res.json().then(r => {
                                                            if(r.returnCode === "20000"){
                                                                displayPoster(r.data);
                                                            }
                                                        })
                                                    }
                                                }).catch(()=>errorToast())
                                            });
                                        })
                                    })
                                // }else{
                                //     displayPoster(r.data);
                                // }
                            }
                        })
                    }
                }).catch(()=> errorToast())
            },500);
        }
    }, [nftLoaded]);

    useEffect(() => {
        if(!loaded){
                document.body.style.overflow='hidden';
        }else{
                 document.body.style.overflow='';
        }
    },[loaded])

    return(
        <div id="poster-container">
            <Loading isShowing={!loaded} backgroundImage={BackgroundImage} text={'努力生成中...'}/>
            <div style={{padding: '31px 0 0 17px',position: 'absolute',left:'0', top: '0',zIndex:6}}>
                <BackButton />
            </div>
            <div style={{position: 'absolute',right:'20px', top: '32px',zIndex:6,width:'40px', height:'40px',background:'white', borderRadius:'20px',display:'flex',alignItems:'center',justifyContent:'center',boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}} onClick={()=>bottomToast('长按图片下载海报')}>
                <img src = {Download} alt='' style={{width:'24px'}}/>
            </div>

            <div ref={stageRef} id='stageDiv' className='stageDiv'>
                    {/*<CommonTitle plant={plant} style={style} title={name} subTitle={`#${tokenId}/${total}`} subTitleBackground={true}/>*/}
                <div id='title-container'>
                    <div className="home-title">
                        <div
                            style={{ color: 'black', fontSize:'22px',marginBottom:'8px',width:'100%'}}
                        >
                            {name}
                        </div>

                    </div>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div className={'sub-title-light'}>
                            {`#${tokenId}/${total}`}
                        </div>
                    </div>

                </div>


                <div style={{borderRadius:'12px',marginTop:'30px',marginBottom:'50px'}}>
                    <img src={nft} alt='' style={{height: '295px', width: '295px', borderRadius:'12px',marginTop:'12px'}}/>

                </div>
                <div className="info-container" >
                    <div className="glass-background">
                        <div
                            style={{ marginBottom: "9px", fontSize: "18px", color: "rgba(16, 16, 16, 0.8)", height: '30px' }}
                        >
                            {'数字藏品信息'}
                        </div>
                        <div className="detail-container">
                            <div className="detail-pair">
                                <div className="detail-key">发行方</div>
                                <div className="detail-value">{publisher}</div>
                            </div>
                            <div className="detail-pair">
                                <div className="detail-key">创建时间</div>
                                <div className="detail-value">{owner?owner.slice(0,10) : null}</div>
                            </div>
                            <div className="detail-pair">
                                <div className="detail-key">创作作者</div>
                                <div className="detail-value">{creator}</div>
                            </div>
                            <div className="detail-pair">
                                <div className="detail-key">区块链唯一编号</div>
                                <div className="detail-value">{address? getSubStr(address):''}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bottomContainer'>
                    <div className='textAndQrcodeContainer'>
                        <img src={QrCode} className='qrCode' alt='' style={{width:'62px'}} />
                        <div className='textContainer'>
                            <div>扫描二维码</div>
                            <div>了解更多信息</div>
                        </div>
                    </div>
                    <div style={{display:'flex',position:'absolute',bottom:'0px',right:'0px'}}>
                        <div style={{paddingRight:'8px',borderRight:'1px #A5A5A5 solid',marginRight:'8px'}}>
                            <img src={Logo} className='logo' alt='' style={{width:'47px'}}/>
                        </div>
                        <div>
                            <img src={cocafe} className='logo' alt='' style={{width:'47px'}}/>
                        </div>

                    </div>

                </div>
                {/*<div style={{width: '100%',height: '20px'}}/>*/}
            </div>
        </div>
    );
}

