import AutoSwiper from "../../components/autoSwiper/AutoSwiper";
import "./home.scss";
import DutyFree from "../../components/DutyFree";
import {useNavigate} from "react-router";
import {useContext, useState} from "react";
import {AuthContext, serverAddress} from "../../App";
import CommonTitle from "../../components/commonTitle/commonTitle";
import platformLight from "../../assets/platform-light.png";
import platform from "../../assets/platform.png"
import light from "../../assets/light.png"
import {responseCode} from "../../config";
import {bottomToast} from "../../utils/toast";


export function Home(props){
    const { plant, title, claimContent, dutyFreeContent, style, backgroundImage, nftInfo, swiperContainer } = props;
    let navigate = useNavigate();
    const { state } = useContext(AuthContext);

    const [currentIndex,setIndex] = useState(0);
    const handleSlideChange = (e) => {
        let index = e.realIndex;
        setIndex(index);
    }

    const claim = () => {
        if(state.isLogin){
            try {
                fetch(
                    serverAddress + "/nfts/freeMint",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken},
                        body: JSON.stringify({"collectionId": 29 })
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            const nft = result.data.nfts;
                            let {name,tokenId,thumbnailURL,collectionInfo} = nft[0];
                            navigate("/congrats?name=" + name + "&tokenId=" + tokenId + "&url=" + thumbnailURL+"&desc=" + collectionInfo.description);
                        }else if(result.returnCode === responseCode.redemptionCodeInvalid){
                            bottomToast("请输入正确的领取码");

                        }else if(result.returnCode === responseCode.redemptionCodeAlreadyUsed){
                            bottomToast("该兑换码已使用");
                        }else if(result.returnCode === "24000"){
                            bottomToast("请输入正确的领取码");
                        }else if(result.returnCode === "20026" ){
                            bottomToast("领悟码错误，请等待30s后重试");
                        }else if(result.returnCode === "23002"){
                            bottomToast("领取码错误次数过多，您的账号已被封禁，请联系客户解封");
                        }else if(result.returnCode === "25000"){
                            bottomToast("领取数量超过限制");
                        }
                        else if(result.returnCode === "20016"){
                            bottomToast("当前拥挤");
                        }
                        else if(result.returnCode === "25001"){
                            bottomToast("NFT已被领取完");
                        }else{
                            bottomToast("未知错误");
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }else{
            navigate('/login');
        }
    }

    return(
        <div id="home-container" style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : "" }}>
            {/* <div id="light-container">
                <img src={light} />
            </div> */}
            <CommonTitle plant={plant} style={style} title={"广州空中二维码"}/>
            <AutoSwiper
                style={style}
                nftInfo={nftInfo} swiperContainer={swiperContainer} spaceBetween={22}
                currentIndex={currentIndex}
                handleSlideChange={handleSlideChange}
            />
            {/*<div id="platform">*/}
            {/*    <img src={platform} style={{width:'100%',zIndex:2,position:'relative'}}/>*/}
            {/*    <img src={platformLight} style={{position:'absolute',left:0,top:0,zIndex:1}}/>*/}
            {/*</div>*/}

            <div id='retrieve-button' onClick={claim}
                 // style={{ backgroundColor: style.claimButton.buttonBackgroundColor, color: style.claimButton.buttonContentColor }}
            >
                {claimContent}
            </div>
            <div className='duty-container'>
                <DutyFree dutyFreeContent={dutyFreeContent} />
            </div>
        </div>
    )
}
