import Button from "@mui/material/Button";
import { useLocation } from "react-router";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../App";
import { Link } from "react-router-dom";
import "./menubar.scss";
import logo from "../assets/menu-icon.png";
import {useNavigate} from "react-router";
import useLogout from "../utils/useLogout";

export default function MenuBar() {
    const { state, dispatch } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    let navigate = useNavigate();
    let logout = useLogout();
    const currentLocation = useLocation();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleLogout = () => {
        logout().then((r) => {
            if(r) navigate('/login');
        })
    }

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    }

    useEffect(()=>{
            window.scrollTo(0, 0);
        }
    ,[currentLocation])


    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Stack direction="row" spacing={2}>
            <div id="menubar" style={{ display: currentLocation.pathname.includes('/login')  || currentLocation.pathname.includes('/poster') ? 'none': 'flex' }}>
                <Button ref={anchorRef} id="composition-button" aria-controls={open ? "composition-menu" : undefined} aria-expanded={open ? "true" : undefined} aria-haspopup="true" onClick={handleToggle}>
                    <div style={{width: '40px', height:'40px',borderRadius: '20px', background:'white',display:'flex',alignItems:'center', justifyContent:'center',boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                        <img className="title" src={logo} alt= ''/>
                    </div>

                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps} style={{transformOrigin: placement === "bottom-start" ? "left top" : "left bottom"}}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                                        <Link to="/"> <MenuItem onClick={handleClose}>首页</MenuItem></Link>
                                        {state.isLogin &&  <Link to="/list"><MenuItem onClick={handleClose}>我的数字藏品</MenuItem></Link>}
                                        <Link to="/about"> <MenuItem onClick={handleClose}>关于藏品</MenuItem></Link>
                                        {
                                            state.isLogin ?
                                                <MenuItem onClick={handleClose}><div onClick={handleLogout}>退出登录</div></MenuItem>
                                                :
                                                <Link to="/login"><MenuItem onClick={handleClose}>登录</MenuItem></Link>
                                        }

                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Stack>
    );
}
