import Detail from "./Detail";
import LeftPlant from '../../assets/left-plant.png';
import RightPlant from '../../assets/right-plant.png';
import nft from "../../assets/nft.png";
import CardFrame from "../../assets/card-frame.png";

export default function DetailConfigPage(){
    return(
        <Detail title={'数字藏品信息'}
                subTitle={'合约地址：0x9A20c1699911FB820aD'}
                style={style}
                plant={plant}
        />
    )
}

const plant = {left: LeftPlant, right: RightPlant};

const style = {
    claimButton: {
        buttonBackgroundColor: "#281EC8",
        buttonContentColor: "#ffffff",
    },
    titleStyle: {
        color: "#ffffff",
        fontSize: "15px",
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        marginTop: '41px'
    },
    background:{
        marginTop: '61px'
    },
    frame:{
        height: '356px',
        width: '293px',
        backgroundImage: CardFrame,
        contentImage: nft,
        content:{
            height: '266px',
            width: '266px'
        }
    }
};