import React from "react";
import { Home } from "./Home.js";
import leftPlant from "./assets/left-plant.svg";
import rightPlant from "./assets/right-plant.svg";
import backgroundImage from "./assets/background.png";
import swiperContainer from '../../assets/swiperBackground.png';
import Background from "../../components/background/background";
import gif1 from './assets/3.jpg';
import gif2 from './assets/2.jpg';
import pic from './assets/1.jpg';
import pic4 from './assets/8.jpg';
import pic5 from './assets/5.png';
import pic6 from './assets/6.png';
import pic7 from './assets/7.jpg';


export default function HomePageConfig() {
  return (
      <div>
        <Background ornamentConfig={[false,false,false, false]} textConfig={false} style={style}/>
        <Home
            plant={plant}
            style={style}
            // title={"有点东西"}
            claimContent={"立即领取"}
            backgroundImage={backgroundImage}
            nftInfo={nftInfo}
            swiperContainer={swiperContainer}
        />
      </div>
  );
}

const nftInfo = [
    {image: pic4, header: '广州空中二维码', desc: '用一台新空调与旧生活告别。'},
    // {image: pic5, header: '森海塞尔CX True Wireless', desc: '在酷享音暴星系，使用森海塞尔耳机，尽享品质声音不妥协，使用CX True Wireless，高中低频演绎出色音乐生活，助你玩转无限精彩。'},
    // {image: pic6, header: '森海塞尔CX Plus', desc: '如果你不想在声音上妥协，那么森海塞尔正是你的优质选择。森海塞尔水晶般清晰的声音、极具下潜力的超重低音，还允许你按照自己的品味来调整，都将拥有无与伦比的震撼体验。'},
    // {image: pic7, header: '冗余的延伸', desc: '宇宙在数字星际空间中舞蹈，延长线是数据冗余累积的痕迹，等待重启。'},
    // {image: gif1, header: 'POP无限MAX键鼠', desc: '在罗技星球可以高效舒适办公。尽情享受工作与娱乐生活。使用罗技POP键鼠神器组合，活出敢性，未来无限可能。'},
    // {image: gif2, header: 'G435玩不设限星球耳机', desc: '太空游戏盛宴MVP选手—G435玩不设限星球耳机，在万众瞩目中耀眼登场。使用罗技G435无线游戏耳机，玩转无拘人生。'},
    // {image: pic, header: '如花来酷·戴上耳机去钓鱼', desc: '生活不一定很酷，但要有自己的态度，听歌钓鱼，与渔获无关，当个Citygirl“钓鱼佬”耐心留住，惊喜慢慢酝酿出来。'}
]

const plant = {left: leftPlant, right: rightPlant};

const style = {
  claimButton: {
    buttonBackgroundColor: "linearGradient(180deg, #0085FE 0%, rgba(0, 133, 254, 0.74) 100%)",
    buttonContentColor: "#ffffff",
  },
  titleStyle: {
    color: "black",
    fontSize: "15px",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    marginTop: '61px'
  },
    background:{
      marginTop: '81px'
    },
  swiper: {
      height: "380px",
      width: "100%",
    container:{
      height:'345px',
      width: '295px'
    },
    content:{
      height: '295px',
      width: '295px'
    }
  },
};
